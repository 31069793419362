import React, { useEffect, useLayoutEffect } from "react";
import Aboutus from "./About-us";
import Services from "./Services";
import Process from "./Process";
import Whyus from "./Whyus";
import Contact from "./Contact";
import Banner from "../components/Banner";
import $ from 'jquery'

function Home() {

  // useEffect(() => {
  //   loadFunctions()
  // }, [])

  return (
    <div className="home">
      <Banner />
      <Aboutus />
      <Services />
      <Process />
      <Whyus />
      <Contact />
    </div>
  );
}

export default Home;
