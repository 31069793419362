import React from "react";
import { Helmet } from 'react-helmet';


function Aboutus() {
  return (
    <section id="about-us" className="padding single-feature page-section">
      {/* <Helmet>
        <title>About us | Codevider</title>
        <meta
          name="description"
          content="Outsourcing development company "
        />
      </Helmet> */}
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-7 text-md-left text-center wow fadeInLeft" data-wow-delay="300ms">
            <div className="heading-title about-us">
              <span>About Us</span>
              <h2 className="darkcolor bottom30">Hire full stack developers</h2>
            </div>
            <h4 className="darkcolor bottom30">Save up to 60% cost, 2x faster</h4>
            <p className="bottom35">Build any type of web application from scratch for all kinds of commercial
            grade, mid-large scale projects with our full stack development services. Hire Full stack
            developers on hourly or full time (dedicated monthly) basis who are skilled with MEAN.js,
            Angular .js, LAMP, Vue.js, Meteor, React.js, Node.js, PHP. CodeVider provides reliable full
            stack web development services with faster turnaround times and better ROI.
              </p>
          </div>
          <div className="col-md-6 col-sm-5 wow fadeInRight" data-wow-delay="350ms">
            <div className="image top50"><img alt="SEO" src="images/photo_about_us.jpg" /></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;
