import React, { } from 'react';
import Nav from './Nav';
import SideMenu from './SideMenu';



function Header() {
  return (

    <header>
      <Nav />
      <SideMenu />
    </header>
  );
}

export default Header;
