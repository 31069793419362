import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { loadFunctions, TechAnim } from "../utilities"
import { useSelector } from 'react-redux'
import PortfolioMasonery from './PortfolioMasonery'

const location = process.env.REACT_APP_BASE_NAME || '/';
const isWindow = () => typeof window.$ === 'function'

function Process() {
  const [checkJquery, setCheckJquery] = useState(0);
  // useEffect(() => {
  //   loadFunctions();
  // }, [window.$])
  useEffect(() => {
    if (typeof window.$ === 'function') {

      if (!window.techAnim) {
        window.techAnim = new TechAnim();
      }

      window.techAnim.init();
      window.$(window).on('resize', function () {
        window.techAnim.startTechs();
      });
    } else {
      const count = checkJquery + 1;
      setCheckJquery(count)
    }

  }, [window.$, checkJquery])

  return (
    <div id="our-process" className="page-section">
      {/* <Helmet>
          <title>Work Process | Codevider </title>
          <meta
            name="description"
            content="Outsourcing development company "
          />
        </Helmet> */}
      {/* WOrk Process*/}
      < section className="padding half-section" >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <div className="heading-title wow fadeInUp" data-wow-delay="300ms">
                <h2 className="darkcolor">Work <span className="fontregular">Process</span> </h2>
                <p className="fontbold bottom25">We are using agile methodology so our process is focus on
                    sprints and is repeated:</p>
              </div>
            </div>
          </div>
          <div className="row">
            <ul className="process-wrapp">
              <li className="darkcolor wow fadeIn col-2" data-wow-delay="300ms">
                <span className="pro-step bottom20 defaultcolor">01</span>
                <p className="fontbold bottom25">Determine Client Needs</p>
                <p>We are going to get and write down your requests.</p>
              </li>
              <li className="darkcolor wow fadeIn col-2" data-wow-delay="400ms">
                <span className="pro-step bottom20">02</span>
                <p className="fontbold bottom25">Team Selection</p>
                <p>Our developer skills will be matched with your requirements</p>
              </li>
              <li className="darkcolor wow fadeIn col-2 different-top-position" data-wow-delay="500ms">
                <span className="pro-step bottom20">03</span>
                <p className="fontbold bottom25">Development</p>
                <p>Selected developer/s will work delicately on your project.</p>
              </li>
              <li className="darkcolor wow fadeIn col-2" data-wow-delay="600ms">
                <span className="pro-step bottom20">04</span>
                <p className="fontbold bottom25">Daily Meeting &amp; Weekly Reporting</p>
                <p>You can organize daily meeting with selected developers. Your project manager will report
                    weekly for tasks progress.</p>
              </li>
              <li className="darkcolor wow fadeIn col-2" data-wow-delay="700ms">
                <span className="pro-step bottom20">05</span>
                <p className="fontbold bottom25">Deployment</p>
                <p>We will give you a development server when you can test your project. On your servers you
                    will have clean code.</p>
              </li>
              <li className="darkcolor wow fadeIn col-2 different-top-position" data-wow-delay="700ms">
                <span className="pro-step bottom20">06</span>
                <p className="fontbold bottom25">On Going &amp; Maintenance</p>
                <p>You can work with the same team to get consistent Productivity.</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/*WOrk Process ends*/}

      {/*Gallery*/}
      <PortfolioMasonery />
      {/* Counters */}
      <section className="padding bg-counter-light parallaxie half-section">
        <div className="container">
          <div className="header-tech" style={{ marginLeft: '7%' }}>
            <div className="tech-animation w-100">
              <div id="con1" title="Gulp" className="tech-container xxx">
                <div className="tech">
                  <img src="./images/techs/gulp-logo.png" alt="Gulp" title="Gulp" />
                </div>
              </div>
              <div id="con2" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/css3-logo.png" alt="Css3" />
                </div>
              </div>
              <div id="con3" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/facebook-api-logo.png" alt="Facebook Api" /></div>
              </div>
              <div id="con4" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/html5-logo.png" alt="Html5" /></div>
              </div>
              <div id="con5" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/javascript-logo.png" alt="Javascript" /></div>
              </div>
              <div id="con6" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/jquery-logo.png" alt="Jquery" /></div>
              </div>
              <div id="con7" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/angular-logo.png" alt="Angular" /></div>
              </div>
              <div id="con8" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/nodejs-logo.png" alt="NodeJs" /></div>
              </div>
              <div id="con9" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/php-logo.png" alt="PHP" /></div>
              </div>
              <div id="con10" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/sass-logo.png" alt="SAS" /></div>
              </div>
              <div id="con11" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/socket-io.png" alt="Socket IO" /></div>
              </div>
              <div id="con12" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/vuejs-logo.png" alt="VueJs" /></div>
              </div>
              <div id="con13" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/mysql-logo.png" alt="Mysql" /></div>
              </div>
              <div id="con14" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/aws.png" alt="AWS" /></div>
              </div>
              <div id="con15" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/dynamodb.png" alt="DynamoDB" /></div>
              </div>
              <div id="con16" className="tech-container">
                <div className="tech">
                  <img src="./images/techs/redis.png" alt="redis" /></div>
              </div>
              <div id="con17" style={{ top: '237.141px', left: '404.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/mongo.png" alt="mongo" /></div>
              </div>
              <div id="con18" style={{ top: '237.141px', left: '214.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/postgress.png" alt="postgress" /></div>
              </div>
              <div id="con19" style={{ top: '237.141px', left: '264.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/rest-api.jpg" alt="rest-api" /></div>
              </div>
              <div id="con20" style={{ top: '237.141px', left: '354.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/graphql.png" alt="graphql" /></div>
              </div>
              <div id="con21" style={{ top: '237.141px', left: '584.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/stripe.png" alt="stripe" /></div>
              </div>
              <div id="con22" style={{ top: '237.141px', left: '884.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/paypal.png" alt="paypal" /></div>
              </div>
              <div id="con23" style={{ top: '237.141px', left: '814.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/google%20maps.png" alt="map" /></div>
              </div>
              <div id="con24" style={{ top: '237.141px', left: '144.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/typescript.png" alt="typescript" /></div>
              </div>
              <div id="con25" style={{ top: '337.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/koa.png" alt="koa" /></div>
              </div>
              <div id="con26" style={{ top: '437.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/hapi.svg" alt="hapi" /></div>
              </div>
              <div id="con27" style={{ top: '537.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/express.png" alt="express" /></div>
              </div>
              <div id="con28" style={{ top: '137.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/zend-framework.png" alt="zend" /></div>
              </div>
              <div id="con29" style={{ top: '37.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/symphony.png" alt="symphony" /></div>
              </div>
              <div id="con30" style={{ top: '87.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/django.png" alt="django" /></div>
              </div>
              <div id="con31" style={{ top: '187.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/flask.png" alt="falsk" /></div>
              </div>
              <div id="con32" style={{ top: '287.141px', left: '934.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/python.png" alt="python" /></div>
              </div>
              <div id="con33" style={{ top: '237.141px', left: '10.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/golang.jpeg" alt="golang" /></div>
              </div>
              <div id="con34" style={{ top: '237.141px', left: '80.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/laravel_logo.png" alt="laravel" /></div>
              </div>
              <div id="con35" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/react.png" alt="react" /></div>
              </div>
              <div id="con36" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/docker.jpg" alt="Docker" /></div>
              </div>
              <div id="con37" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/microservices.png" alt="microservices" /></div>
              </div>
              <div id="con38" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/jenkins.png" alt="jenkins" /></div>
              </div>
              <div id="con39" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/git.png" alt="git" /></div>
              </div>
              <div id="con40" style={{ top: '237.141px', left: '160.797px' }} className="tech-container">
                <div className="tech">
                  <img src="./images/techs/slack.png" alt="slack" /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Counters ends*/}
    </div >
  );
}

// const mapStateToProps = (state) => ({ project: state.project })
export default Process;