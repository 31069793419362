import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Contact() {
  useEffect(() => {
    if (typeof window.$ === 'function') {
      window.$("#submit_btn").click(function (ev) {
        ev.preventDefault();
        var user_name = window.$('input[name=name]').val();
        var user_email = window.$('input[name=email]').val();
        var user_phone = window.$('input[name=phone]').val();
        var user_message = window.$('textarea[name=message]').val();
        var budged = window.$('select[name=user-budget]').val();

        //simple validation at client's end
        var post_data, output;
        var proceed = true;
        if (user_name == "") {
          proceed = false;
        }
        if (user_email == "") {
          proceed = false;
        }
        if (user_phone == "") {
          proceed = false;
        }

        if (user_message == "") {
          proceed = false;
        }
        //everything looks good! proceed...
        if (proceed) {

          //data to be sent to server
          post_data = { 'userName': user_name, 'userEmail': user_email, 'userPhone': user_phone, 'userMessage': user_message, 'budget': budged };

          //Ajax post data to server
          window.$.post('https://codevider.com/contact.php', post_data, function (response) {

            //load json data from server and output message
            if (response.type == 'error') {
              output = '<div class="alert-danger" style="padding:10px; margin-bottom:25px;">' + response.text + '</div>';
            } else {
              output = '<div class="alert-success" style="padding:10px; margin-bottom:25px;">' + response.text + '</div>';

              //reset values in all input fields
              window.$('.getin_form input').val('');
              window.$('.getin_form textarea').val('');
              window.$('.getin_form select').val('');
            }

            window.$("#result").hide().html(output).slideDown();
          }, 'json');

        }
        else {
          output = '<div class="alert-danger" style="padding:10px; margin-bottom:25px;">Please provide the missing fields.</div>';
          window.$("#result").hide().html(output).slideDown();
        }

      });
    }
  }, [window.$])
  return (
    <section id="contact-us" className="padding_top page-section">
      {/* <Helmet>
          <title>About us | Codevider</title>
          <meta
            name="description"
            content="Outsourcing development company "
          />
        </Helmet> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="heading-title heading_space wow fadeInUp" data-wow-delay="300ms">
              <span>Lets Get In Touch</span>
              <h2 className="darkcolor">Contact CodeVider</h2>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 margin_bottom">

            <form className="getin_form wow fadeInUp" data-wow-delay="400ms" >
              <div className="row">
                <div className="col-sm-12" id="result" />
                <div className="col-md-6 col-sm-6">
                  <div className="form-group bottom35">
                    <label id="yourName" htmlFor="your_name" className="invisible">YourName: </label>
                    <input
                      className="form-control"
                      aria-labelledby="yourName"
                      type="text"
                      placeholder="Your Name:"
                      required
                      id="your_name" name="name"
                    />
                  </div >
                  <div className="form-group bottom35">
                    <label id="yourNr" htmlFor="phone" className="invisible">YourName: </label>
                    <input
                      className="form-control"
                      aria-labelledby="yourNr"
                      type="tel"
                      placeholder="Contact Number:"
                      id="phone"
                      name="phone"
                    />
                  </div >
                  <div className="form-group bottom35">
                    <label id="yourEmail" htmlFor="email" className="invisible">YourName: </label>
                    <input
                      className="form-control"
                      aria-labelledby="yourEmail"
                      type="email"
                      placeholder="Email Id:"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="form-group bottom35">
                    <label id="budget" htmlFor="user-budget" className="invisible">YourName: </label>
                    <select className="form-control" aria-labelledby="budget" name="budget" id="user-budget" >
                      <option value>What's your Budget</option>
                      <option value="$5000 or less">&lt;$5000</option>
                      <option value="$5000-$10000">$5000-$10000</option>
                      <option value="$10000-$25000">$10000-$25000</option>
                      <option value="$25000-$50000">$25000-$50000</option>
                      <option value="$50000 or more">&gt;$50000</option>
                    </select>
                  </div >
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group bottom35 textarea">
                    {/* <label id="yourRequirements" for="message" className="invisible">YourName: </label>  aria-labelledby="yourRequirements"*/}
                    <textarea
                      className="form-control"
                      placeholder="Your Requirements"
                      id="message"
                      name="message"
                      defaultValue={""}
                    />
                  </div >
                </div>
                <div className="col-sm-12 text-center">
                  <button type="submit" className="button btnprimary" id="submit_btn">
                    Send Your Request
                  </button>
                </div>
              </div>

            </form>
          </div>
          <div className="col-md-12 col-sm-12 margin_bottom wow fadeInUp" data-wow-delay="350ms">
            <div className="row other-contact">
              <div className="col-md-4 col-sm-4 our-address top40 text-center">
                <h5 className="bottom25">Our Address</h5>
                <p className="bottom15">Rruga Mine Peza 2, Mbi Air Albania, Hyrja 1, Ap 1 1016, Tirana, Albania.
                </p>
                <Link className="pickus" to="https://goo.gl/maps/7MfeCaypyvQ8DxxL6" data-text="Get Directions">Get Directions</Link>
              </div>
              <div className="col-md-4 col-sm-4 our-address top40 text-center">
                <h5 className="bottom25">Our Phone</h5>
                <p className="bottom15">Office Telephone : +1 224-788-0689
                  <span className="block">Mobile : +355 695877742</span>
                </p>
                <Link className="pickus" to="tel:+355 695877742" data-text="Call Us">Call Us</Link>
              </div>
              <div className="col-md-4 col-sm-4 our-address top40 text-center">
                <h5 className="bottom25">Our Email</h5>
                <p className="bottom15">Main Email : info@codevider.com <span className="block">HR :
                    hr@codevider.com</span> </p>
                <Link className="pickus" to="#contact-us" data-text="Send a Message">Send a Message</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Location Map here*/}
      <div id="map-container">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe width="100%" height={500} id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11985.627180909216!2d19.8141528!3d41.3217668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x868519590dbd2f21!2sCodeVider!5e0!3m2!1sen!2s!4v1569844577642!5m2!1sen!2s" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact
