import React, { useEffect } from "react";
import { Link } from "react-router-dom";


function Banner() {

  // const { pathname } = useLocation();
  useEffect(() => {
    if (window.$ && window.$("#rev_single") && window.$("#rev_single").revolution) {
      window.$("#rev_single").revolution({
        sliderType: "hero",
        jsFileLocation: "js/revolution",
        sliderLayout: "fullscreen",
        scrollbarDrag: "true",
        dottedOverlay: "none",
        delay: 9000,
        navigation: {},
        responsiveLevels: [1240, 1024, 778, 480],
        visibilityLevels: [1240, 1024, 778, 480],
        gridwidth: [1170, 1024, 778, 480],
        gridheight: [868, 768, 960, 720],
        lazyType: "none",
        parallax: {
          type: "scroll",
          origo: "slidercenter",
          speed: 400,
          levels: [10, 15, 20, 25, 30, 35, 40, -10, -15, -20, -25, -30, -35, -40, -45, 55]
        },
        shadow: 0,
        spinner: "off",
        autoHeight: "off",
        fullScreenAutoWidth: "off",
        fullScreenAlignForce: "off",
        fullScreenOffsetContainer: "",
        disableProgressBar: "on",
        hideThumbsOnMobile: "off",
        hideSliderAtLimit: 0,
        hideCaptionAtLimit: 0,
        hideAllCaptionAtLilmit: 0,
        debugMode: false,
        fallbacks: {
          simplifyAll: "off",
          disableFocusListener: false
        }
      });
    }
  }, [window.$])
  return (

    <section className="no-padding wow fadeIn no-transition page-section" id="home">
      <div id="rev_single_wrapper" className="rev_slider_wrapper fullscreen-container rev-slider" data-alias="inspiration-header" data-source="gallery" style={{ backgroundColor: 'transparent', padding: '0px' }}>
        {/* start revolution slider 5.4.1 fullscreen mode */}
        <div id="rev_single" className="rev_slider fullscreenbanner" data-version="5.4.1">
          <ul>
            {/* slide */}
            <li data-index="rs-layers" data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={500} data-rotate={0} data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
              {/* main image */}
              <img src="images/home_bg-01.jpg" data-bgcolor="#e0e0e0" alt="home-background" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />

              <div className="tp-caption tp-resizeme mb" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-70','-70']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-transform_idle="o:1;" data-transform_in="x:-50px;opacity:0;s:2000;e:Power3.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-start={1000} data-splitin="none" data-splitout="none" style={{ zIndex: 20 }}>
                <h1 className="text-capitalize fontbold darkcolor"> Outsourced Coding </h1>
              </div>
              <div className="tp-caption tp-resizeme mb" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']" data-whitespace="nowrap" data-responsive_offset="on" data-width="['none','none','none','none']" data-type="text" data-textalign="['center','center','center','center']" data-transform_idle="o:1;" data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;" data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-start={1000} data-splitin="none" data-splitout="none" style={{ zIndex: 20 }}>
                <h3 className="text-capitalize darkcolor" style={{ fontWeight: 500 }}>made with Love</h3>
              </div>
              <div className="tp-caption tp-resizeme whitecolor mb text-break" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['70','70','70','70']" data-whitespace="normal" data-responsive_offset="on" data-width="['900','none','780','450']" data-fontsize="['24','24','24','24']" data-transform_idle="o:1;" data-textalign="['center','center','center','center']" data-transform_in="z:0;rX:0deg;rY:0;rZ:0;sX:2;sY:2;skX:0;skY:0;opacity:0;s:1000;e:Power2.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;" data-mask_in="x:0px;y:0px;s:inherit;e:inherit;" data-start={1500} data-height={85} style={{ zIndex: 20 }}>
                <p className="darkcolor text-break" style={{ fontWeight: 400, textAlign: 'center' }}>We are a professional web development outsourcing company based in Albania.<br />With us, you can be sure in quality
                    software development outsourcing services by reducing costs/time-to-market.</p>
              </div>
              <div className="tp-caption tp-resizeme mb" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['160','160','160','160']" data-whitespace="nowrap" data-transform_idle="o:1;" data-transform_in="y:[100%];z:1000;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1500;e:Power4.easeInOut;" data-width="['450','none','780','400']" data-transform_out="s:900;e:Power2.easeInOut;s:900;e:Power2.easeInOut;" data-start={2000} data-splitin="none" data-splitout="none" data-responsive_offset="on" style={{ zIndex: 20 }}>
                <Link className="button btnsecondary pagescroll mr-2 btn-mob" to="#our-services">Our Services</Link>
                <Link className="button btnprimary pagescroll  btn-mob" to="#contact-us">Start a Project with us</Link>
              </div>
              {/* layer nr. 15 */}
              <div className="tp-caption rev-scroll-btn revs-dark" id="layers-thir" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['bottom','bottom','bottom','bottom']" data-voffset="['90','90','70','50']" data-width={30} data-height={45} data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;px&quot;,&quot;delay&quot;:&quot;&quot;,&quot;speed&quot;:1000}]" data-basealign="slide" data-frames="[{&quot;from&quot;:&quot;y:-50px;opacity:0;&quot;,&quot;speed&quot;:1500,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:800,&quot;ease&quot;:&quot;Power4.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{ zIndex: 19, minWidth: '35px', maxWidth: '55px', whiteSpace: 'nowrap', fontWeight: 400, borderColor: 'rgba(0, 0, 0, 1.00)', borderStyle: 'solid', borderWidth: '2px', borderRadius: '23px 23px 23px 23px', boxSizing: 'border-box', MozBoxSizing: 'border-box', WebkitBoxSizing: 'border-box', cursor: 'pointer' }}>
                <span />
              </div>
            </li>
          </ul>
          <div className="tp-bannertimer tp-bottom" style={{ visibility: 'hidden !important' }} />
        </div>
      </div>
    </section>

  );
}

export default Banner;
