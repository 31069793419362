import React, { Component } from 'react'
import NotFound from '../components/NotFound';
class ErrorBoundary extends Component {

    state = {
        hasError: false,
        errorMessage: ' '
    }
    componentDidCatch =
        (error, info) => {
            this.setState({ hasError: true, errorMessage: error })
        }
    render() {

        if (this.state.hasError) {
            // return (<h2 className="error-text"> {this.state.errorMessage}</h2>);
            return <NotFound />
        } else {
            return this.props.children
        }


    }
}

export default ErrorBoundary;