import { GET_PORTFOLIO } from './type'

const initialState = {
    projects: [],
    loading: true
}

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_PORTFOLIO:
            return {
                ...state,
                projects: action.payload,
                loading: false

            }
        default: return state
    }

}