
export const selector = ({ projects }) => ({ projects })
// export const isWindow = () => typeof window.$ === 'function'

export class TechAnim {
    init = () => {
        this.hasInit = true;
        this.totalTechs = 40;
        this.startTechs();
        setTimeout(this.techAnimation, 500)
    }
    refresh = () => {
        this.startTechs();
        setTimeout(this.techAnimation, 500)
    }
    techAnimation = () => {
        const i = this;
        const rowTech = window.$(".header-tech");
        window.$(".tech-animation .tech-container").each(function () {
            const t = this,
                n = window.$(t).position();
            window.$(t).on("mouseover", function (o) {
                let rt = rowTech.offset();
                let e = o.pageX - rt.left;
                let a = o.pageY - rt.top;
                let s = window.$(t).position();
                let c = s.left + window.$(t).width() / 2;
                let l = s.top + window.$(t).height() / 2;
                let r = "+=0";
                let d = "+=0";
                /* eslint-disable no-unused-expressions */
                s.top > 25 && (Math.abs(e - c) <= 62.5 && a - l >= 0 && (d = "-=75"),
                    s.left > 25 && (e - c >= 0 && a - l >= 0 && (r = "-=75", d = "-=75"),
                        Math.abs(a - l) <= 62.5 && e - c >= 0 && (r = "-=75")), s.left + 175 < rowTech.width() && (e - c <= 0 && a - l >= 0 && (r = "+=75", d = "-=75"), Math.abs(a - l) <= 62.5 && e - c < 0 && (r = "+=75"))), s.top + 175 < rowTech.height() && (Math.abs(e - c) <= 62.5 && a - l < 0 && (d = "+=75"), s.left > 25 && (e - c >= 0 && a - l <= 0 && (r = "-=75", d = "+=75"), Math.abs(a - l) <= 62.5 && e - c >= 0 && (r = "-=75")), s.left + 175 < rowTech.width() && (e - c <= 0 && a - l <= 0 && (r = "+=75", d = "+=75"), Math.abs(a - l) <= 62.5 && e - c < 0 && (r = "+=75"))), window.$(t).stop(), window.$(t).animate({
                            left: r,
                            top: d
                        }, 750, function () {
                            const tech = window.$(t);
                            r = "+=0", d = "+=0", tech.delay(1e3).animate({
                                left: n.left,
                                top: n.top
                            }, 1500)
                        })
            })
        })
    }
    startTechs = () => {

        const techContainer = window.$(".header-tech .tech-animation");

        const w = techContainer?.width();
        const h = techContainer?.height();
        const c = Math.floor(w / 100);
        const r = Math.ceil(this.totalTechs / c);
        techContainer?.height(r * 100);
        let t = 0;
        for (let ir = 0; ir < r; ir++) {
            for (let ic = 0; ic < c; ic++) {
                t++;
                // console.log(window.$(`#con${t}`), ic * 100, ir * 100);
                // window.$(`#con${t}`).css('top', `${ir * 100}px`);
                // window.$(`#con${t}`).css('left', `${ic * 100}px`);
                window.$(`#con${t}`).animate({
                    left: ic * 100,
                    top: ir * 100
                }, 0);
            }

        }

    }
}
export const loadFunctions = () => {
    setTimeout(() => {
        if (typeof window.$ === 'function') {
            console.log(window.$("#portfolio-measonry"));
            window.$("#portfolio-measonry").cubeportfolio({
                filters: '#measonry-filters',
                loadMoreAction: 'click',
                layoutMode: 'grid',
                defaultFilter: '*',
                animationType: "scaleSides",
                gapHorizontal: 30,
                gapVertical: 30,
                gridAdjustment: "responsive",
                mediaQueries: [{
                    width: 1500,
                    cols: 2
                }, {
                    width: 1100,
                    cols: 2
                }, {
                    width: 768,
                    cols: 2
                }, {
                    width: 480,
                    cols: 1
                }, {
                    width: 320,
                    cols: 1
                }],
            });
            window.$("#portfolio-measonry").cubeportfolio("destroy")
            const flatGallery = window.$("#flat-gallery");
            if (flatGallery.hasClass('cbp')) {
                flatGallery.cubeportfolio('destroy');
            }
            flatGallery.cubeportfolio({
                layoutMode: 'grid',
                filters: '#flat-filters',
                defaultFilter: '*',
                animationType: "quicksand",
                gapHorizontal: 30,
                gapVertical: 30,
                gridAdjustment: "responsive",
                mediaQueries: [{
                    width: 1500,
                    cols: 3
                }, {
                    width: 1100,
                    cols: 3
                }, {
                    width: 768,
                    cols: 2
                }, {
                    width: 480,
                    cols: 1
                }, {
                    width: 320,
                    cols: 1
                }],
            });
            window.$("#ourteam-slider").owlCarousel({
                items: 3,
                margin: 30,
                dots: true,
                nav: false,
                responsive: {
                    1280: {
                        items: 3,
                    },
                    600: {
                        items: 2,
                    },
                    320: {
                        items: 1,
                    },
                }
            });
        } else {
            console.log("here");
        }
    }, 300)
}