import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import getStoreCodevider from './store/portfolioStore'
import { Provider } from 'react-redux'



ReactDOM.render(
  <Provider store={getStoreCodevider}>
    <App />
  </Provider>,
  document.getElementById('root')
);


