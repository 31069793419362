import React, { Component } from 'react'
import { Link } from 'react-router-dom';
export default class NotFound extends Component {
    render() {
        return <div>
            <section className="page-header parallaxie bglight center-block padding_top_m py-5" >
                <div className="container" >
                    <div className="row" >
                        <div className="col-sm-12" >
                            <div className="page-titles text-left" >
                                <h2 className=" font-light mt-5 font-weight-bold text-capitalize" > 404 Error </h2>
                                <ul className="breadcrumb justify-content-left" >
                                    <li className="breadcrumb-item text-primary" > <Link to="/" onClick={() => { window.location.href = "/"; }}> Home </Link></li >
                                    <li className="breadcrumb-item active" aria-current="page" > 404 </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="error" className="padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-2"></div>
                        <div className="col-md-6 col-sm-8 text-center">
                            <div className="error wow zoomIn">
                                <h1>404</h1>
                                <h2>404</h2>
                            </div>
                            <p className="heading_space">We are sorry, the page you want isn’t here anymore. May be one of the links below can help !</p>
                            <Link to="/" className="button btnprimary btn-gradient-hvr wow fadeInLeft"> back to home </Link>
                            {/* <a href="index.html" className="button btnsecondary btn-gradient-hvr wow fadeInRight">Get a quote</a> */}
                        </div>
                        <div className="col-md-3 col-sm-2"></div>
                    </div>
                </div>
            </section>
        </div >
    }
}