import { combineReducers } from 'redux'
import projectsReducer from './projectsReducer'

export default combineReducers({
    project: projectsReducer
})

/*
Reducer  where we combine all other reducer functions whih manage independent parts of hte state and you export them as a single function, this way you pass it to the the creteStore


*/