import React, { Component } from 'react'
import SliderProject from './SliderProject'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPortfolio } from '../store/action'
import { withRouter } from "react-router";
import { Helmet } from 'react-helmet';
import { loadFunctions } from '../utilities'
import NotFound from './NotFound'

const location = process.env.REACT_APP_BASE_NAME || '/';


class SinglePortfolio extends Component {

    constructor(props) {
        super(props)

        this.state = {
            project: null,
            relatedProjects: [],
            pojectNotFound: false
        }
    }

    init() {

        const { projects: { projects }, match: { params: { projectUrl: paramUrl } } } = this.props

        const project = projects.find(({ url }) => url === paramUrl)
        if (project) {
            const relatedProjects = projects.filter(({ category: projectsCategories }) => project.category.some(currentCategory => projectsCategories.includes(currentCategory)))
            this.setState({ project, relatedProjects }, loadFunctions)
        } else {
            this.setState({
                projectNotFound: true,
            })
        }
    }

    componentDidMount() {
        if (this.props.projects.projects.length) {
            this.init()
        } else {
        }
    }


    componentDidUpdate(prevProps) {
        // console.log(this.props, prevProps);
        const { match: { params: { projectUrl } } } = this.props
        const { match: { params: { projectUrl: prevProjectUrl } } } = prevProps


        if ((projectUrl !== prevProjectUrl || (!this.state.project && !this.state.projectNotFound)) && this.props.projects.projects.length) {
            this.init()
        }

    }

    render() {

        return this.state.projectNotFound ? <NotFound /> : (
            <div key={this.state.project?.id}>
                <Helmet>
                    {/*<title>{this.state.project?.title}</title> */}
                    <meta
                        name="description"
                        content="This is a single project of codevider"
                    />
                    <meta
                        name="keywords"
                        content={`Development company, Software, Application, Mobile App, Website, Wordpress Developer, Outsourcing Projects,talented developers, Frontend Developer, Backend Deleloper, Programs, Codevider, Code, Server, API, Nodejs, Reactjs, AWS, Mysql, Sass, Stripe, Paypal, RestAPI, django, Flask, Python, Docker, Jenkins, Git, Postman, Laravel, Php, JavaScript, ES6, Express, Typescript, koa, hapi, Html5, Angular, Vue, mondoDB, jquery, Symphony, Code in Albania, Develop Website in Albania, Frontend Development in Albania, Web Development Agencies in Albania, Web Development, Web Portfolio,website development outsourcing company, web software outsourcing, web outsourcing company, web development stack, web developers for hire,web app developers, outsource web developer, hire a developer for an app, developer for hire, development outsource, outsource web app development, full stack web developer, angular developers, outsource web application development,   outsource php development , outsource web development services, software design company  , best software developer , software programming company, ${this.state.project?.title}`}
                    />

                </Helmet>
                <section className="page-header parallaxie bglight center-block padding_top_m py-5" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-sm-12" >
                                <div className="page-titles text-left" >
                                    <h2 className=" font-light mt-5 font-weight-bold text-capitalize" id="ajxTitle" > {this.state.project?.title} </h2>
                                    <ul className="breadcrumb justify-content-left" >
                                        <li className="breadcrumb-item text-primary" > < Link to="/" onClick={() => { window.location.href = "/"; }}> Home </Link></li >
                                        <li className="breadcrumb-item active" aria-current="page" > Portfolio Details </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="our-blog" className="mt-5" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-md-8" >
                                <div className="news_item " >
                                    <div className="news_desc">
                                        <SliderProject dataFromParent={this.state.project?.img} />

                                        <h3 className="text-capitalize font-light darkcolor pt-3"> Add text here if you like </h3>
                                        {this.state.project?.description && this.state.project.description?.map((desc, i) => (
                                            <div className="row top30 bottom35 ajxDesc" key={i}>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <h5 > PROJECT: </h5>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <p className=" ml-2" > {desc?.projectStatement}</p>
                                                </div>

                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <h5 > CLIENT: </h5>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <p className=" ml-2" > {desc?.clientDescription} </p>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <h5 > SOLUTIONS: </h5>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10 " >
                                                    <p className=" ml-2" > {desc?.projectSolution} </p>
                                                </div>

                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <h5 > TECHNICAL SKILLS: </h5>
                                                </div>
                                                <div className="col-lg-9 col-md-9 col-sm-6 top10" >
                                                    <p className=" ml-2" > {desc?.technicalSkills} </p>
                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 " >
                                <aside className="sidebar whitebox" >
                                    <div className="widget heading_space top40" >
                                        <img src="/images/logo-dark.svg" alt="dark-logo" />
                                        <h5 className="top20 mb-1 " > < i className="fa fa-user" aria-hidden="true" /> < span id="ajxClient" > {this.state.project?.client} </span> </h5 >
                                        <h5 className="top20 mb-1 " > < i className="fa fa-globe" aria-hidden="true" /> < a href="#project-link" > Project link </a> </h5 >
                                    </div>
                                    <div className="widget heading_space" >
                                        <h4 className="text-capitalize darkcolor bottom20" > Project Status( < small > <span className=" small text-primary" id="ajxStatus" > {this.state.project?.status} </span></small > ) </h4>
                                        <p className="meta-tags top20 bottom20" > <i className="fa fa-calendar startProject" /> Start:
                                <       span id="ajxSdata" > {this.state.project?.startDate} </span>
                                        </p >
                                        <p className="meta-tags top20 bottom20" >
                                            <i className="fa fa-calendar endProject" /> {this.state.project?.endDate ? ` End: ${this.state.project.endDate}` : `Ongoing Project`} </p>
                                        <p className="top30 bottom35" />
                                        <h4 className="text-capitalize darkcolor bottom20" > Categories </h4>
                                        <ul className="webtags  "  >

                                            {this.state.project?.category && this.state.project.category?.map((cat, iX) => (
                                                <li key={iX}><span> {cat}</span> </li>
                                            ))}
                                        </ul>
                                        <p className="top30 bottom35" />
                                        <h4 className="text-capitalize darkcolor bottom20" > Technical Skills </h4>
                                        <ul className="webtags  " id="ajxSkills" >
                                            {this.state.project?.skills && this.state.project.skills?.map((sk, iN) => (
                                                <li key={iN} ><span> {sk} </span></li>
                                            ))}
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                        <div className="row top30 bottom35" >
                            <div className="col-md-10 top10 ajxDesc bottom20 text-left"  ><h3> Team  </h3></div>
                            <div className="col-md-12">
                                <div id="our-team" className="padding_sm bglighter">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div id="ourteam-slider" className="owl-carousel">
                                                {this.state.project?.team && this.state.project.team?.map((teamMemeber, indx) => (
                                                    <div className="item" key={indx}>
                                                        <div className="team-box wow fadeInUp" data-wow-delay="300ms">
                                                            <div className="image">
                                                                <img src={teamMemeber.img} alt={teamMemeber.role} />
                                                            </div>
                                                            <div className="team-content gradient_bg whitecolor">
                                                                <h3 className="bottom10">{teamMemeber.name}</h3>
                                                                <p className="bottom40">{teamMemeber.role}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div >
                </section >

                <section className="" >
                    <div className="container">

                        <div className="col-md-10 bottom30 top100" >
                            <h3>{this.state.relatedProjects.length ? `Related Projects` : `This is one of a kind project for our company. We don't have similar projects yet.`}</h3>
                        </div>
                        <div id="flat-gallery">
                            {this.state.relatedProjects.filter(({ url }) => url !== this.props.match.params.projectUrl).sort(() => Math.random() - 0.5).slice(1, 4).map((project, inx) => (
                                <div
                                    key={inx}
                                    className={`cbp-item portfolioItem ${project.category.join(' ')} ${project.status === `active` ? `active` : `completed`}`}
                                // onClick={() => { window.location.href = `${location}portfolio/${project.url}` }}
                                >
                                    <img src={project.featuredImage} alt={project.title} className="featuredImg" />
                                    <Link to={`/portfolio/${project.url}`} >
                                        <div className="overlay center-block whitecolor animate__fadeInTopLeft">
                                            <h4 className="top20 mb-1 font-weight-bold titleProject" > {project.title} </h4>

                                            <p className="float-left w-75 text-center">
                                                <span>Categories: <br />
                                                    <span > {project.category.join(', ')}</span>
                                                </span>
                                                <span className="catProjectList"></span>
                                            </p>
                                            <p className="float-left">
                                                <span>Started on: </span>
                                                <span className="projectStartDate">{project.startDate}</span>
                                            </p>
                                            <p className="float-left endDate">
                                                {project.endDate ? `Finished on : ${project.endDate}` : `Ongoing Project`}
                                            </p>
                                            {/* <Link
                                            type="button"
                                            className="btn btn-outline-light btnProject"
                                            to={`/portfolio/single-portfolio/${p.id}`}
                                            onClick={() => { window.location.href = `/portfolio/single-portfolio/${project.url}` }}
                                            >
                                                More details
                                            </Link> */}
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </section >


            </div >
        );
    }
}
const mapStateToProps = (state) => ({ projects: state.project })

export default connect(mapStateToProps, { getPortfolio })(withRouter(SinglePortfolio));