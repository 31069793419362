import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

const location = process.env.REACT_APP_BASE_NAME || '/';
function Nav() {

  const location = useLocation();

  const [activeClass, setActiveClass] = React.useState("");

  React.useEffect(() => {
    if (window.scrollY >= 0) {
      window.addEventListener('scroll', (e) => {
        let activeClass = '';
        if (window.scrollY > 3) {
          activeClass = 'fixedmenu';
          setActiveClass(activeClass);
        }
        setActiveClass(activeClass);
      });
    }


  }, []);
  const pagescroll = location.pathname === '/' ? 'pagescroll' : '';
  useEffect(() => {
    // if (pagescroll !== '') { 
    if (typeof window.$ === 'function') {
      var navLink = window.$("a.pagescroll");

      if (navLink.length) {
        navLink.on("click", function (event) {
          if (typeof window.$(this.hash).offset() !== "undefined") {
            event.preventDefault();
            let activeLink = window.$("a.pagescroll.active");
            if (activeLink) {
              activeLink = Array.isArray(activeLink) ? activeLink : [activeLink];
              activeLink.forEach(element => {
                element.removeClass('active');
              });
            }

            window.$(event.target).addClass('active');
            window.$("html,body").animate({
              scrollTop: window.$(this.hash).offset().top
            }, 200);

          }
        });
      }
    }
  }, [pagescroll, window.$])
  return (
    <nav className={`navbar navbar-expand-lg bg-transparent-light static-nav ${activeClass}`} >
      <div className="container">
        <Link to="/" className="navbar-brand"
        >
          <img src="/images/logo-dark.svg" alt="dark-logo" />
        </Link>
        <div className="collapse navbar-collapse" id="xenav" >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active" >
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#home"
              >
                Home
                </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#about-us"
              // to="/about-us"
              // onClick={about}
              >
                About us
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#our-services"
              // onClick={services}
              >
                Our Services
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#our-process"
              // onClick={process}
              >
                Work Process
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#portfolio_top"
              // onClick={portfolio}
              >
                Portfolio
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#why-us"
              // onClick={whyus}
              >
                Why Us
              </NavHashLink>
            </li>
            <li className="nav-item">
              <NavHashLink
                className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                to="/#contact-us"
              // onClick={contact}
              >
                Contact
              </NavHashLink>
            </li>
          </ul>
        </div>
      </div>
      {/*side menu open button*/}
      <Link className="d-lg-inline-block sidemenu_btn" id="sidemenu_toggle" title="mobile-toggle">
        <span />
        <span />
        <span />
      </Link>
      <div className=" mt-4" id="social-menu-links">
        <ul className="social-icons small bottom25 wow fadeInUp" data-wow-delay="300ms">
          <li><Link to="https://www.facebook.com/Codevider-865159267157916" target="_blank" title="facebook"><i className="fa fa-facebook" /> </Link> </li>
          <li><Link to="https://www.linkedin.com/company/codevider" target="_blank" title="linked-in"><i className="fa fa-linkedin" /> </Link> </li>
        </ul>
      </div>
    </nav >
  );
}

export default Nav;
