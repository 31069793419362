import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";


function SideMenu() {
  // const { pathname } = useLocation();

  const location = useLocation();
  const pagescroll = location.pathname === '/' ? 'pagescroll' : '';
  useEffect(() => {
    // if (pagescroll !== '') {
    if (typeof window.$ === 'function') {
      var navLink = window.$("a.pagescroll");
      // }
      if (navLink.length) {
        navLink.on("click", function (event) {
          if (typeof window.$(this.hash).offset() !== "undefined") {
            event.preventDefault();
            let activeLink = window.$("a.pagescroll.active");
            if (activeLink) {
              activeLink = Array.isArray(activeLink) ? activeLink : [activeLink];
              activeLink.forEach(element => {
                element.removeClass('active');
              });
            }
            window.$(event.target).addClass('active');
            window.$("html,body").animate({
              scrollTop: window.$(this.hash).offset().top
            }, 200);
          }
        });
      }
    }
  }, [pagescroll, window.$])
  return (
    <div>
      <div className="side-menu">
        <div className="inner-wrapper">
          <span className="btn-close" id="btn_sideNavClose"><i /><i /></span>
          <nav className="side-nav">
            <ul className="navbar-nav w-100">
              <li className="nav-item active" >
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#home"
                >
                  Home
                </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#about-us"
                // to="/about-us"
                // onClick={about}
                >
                  About us
              </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#our-services"
                // onClick={services}
                >
                  Our Services
              </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#our-process"
                // onClick={process}
                >
                  Work Process
              </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#portfolio_top"
                // onClick={portfolio}
                >
                  Portfolio
              </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#why-us"
                // onClick={whyus}
                >
                  Why Us
              </NavHashLink>
              </li>
              <li className="nav-item">
                <NavHashLink
                  className={`nav-link ${pagescroll}`}//"nav-link pagescroll"
                  to="/#contact-us"
                // onClick={contact}
                >
                  Contact
              </NavHashLink>
              </li>

            </ul>
          </nav>

          <div className="side-footer w-100">
            <ul className="social-icons-simple white top40">
              <li><Link to="https://www.facebook.com/Codevider-865159267157916" target="_blank" title="facebook"><i className="fa fa-facebook" /> </Link> </li>
              <li><Link to="https://www.linkedin.com/company/codevider" target="_blank" title="linked-in"><i className="fa fa-linkedin" /> </Link> </li>
            </ul>
            <p className="whitecolor">©
                CodeVider</p>
          </div>
        </div>
      </div>
      {/* <Link id="close_side_menu" onClick={() => setOpen(false)} to="javascript:void(0);" title="close-menu" /> */}
    </div>
  );
}

export default SideMenu;