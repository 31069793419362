import React from "react";
import { Link } from "react-router-dom";


function Footer() {

    // const { pathname } = useLocation();

    return (

    <footer id="site-footer" className="padding_half">
        <div className="container">
            <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
                <img src="/images/logo-dark.svg" alt="dark-logo" className="footer-logo bottom45" />
                <ul className="social-icons small bottom25 wow fadeInUp" data-wow-delay="300ms">
                <li><Link  to="https://www.facebook.com/Codevider-865159267157916" target="_blank" title="facebook"><i className="fa fa-facebook" /> </Link> </li>
                <li><Link  to="https://www.linkedin.com/company/codevider" target="_blank" title="linked-in"><i className="fa fa-linkedin" /> </Link> </li>
                </ul>
                <p className="copyrights wow fadeInUp" data-wow-delay="350ms"> ©
                CodeVider.</p>
            </div>
            </div>
        </div>
    </footer>
   

    );
}
  
  export default Footer;
  