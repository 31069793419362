import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./screens/Home";
import Aboutus from "./screens/About-us";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Portfolio from "./components/Portfolio";
import SinglePortfolio from "./components/SinglePortfolio";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { connect } from "react-redux";
import { getPortfolio } from "./store/action"
import { Helmet } from 'react-helmet';
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

const location = process.env.REACT_APP_BASE_NAME || '/'


function App({ getPortfolio }) {
  useEffect(() => {
    getPortfolio()
  }, [])

  return (
    <Router
    // basename={location}
    >
      {/* <ErrorBoundary> */}
      <Helmet>
        <title> Codevider - Outsouring development company  </title>
        <meta
          name="description"
          content="We are a professional web development outsourcing company based in Albania. With us, you can be sure in quality software development outsourcing services by reducing costs/time-to-market."
        />
        <meta
          name="keywords"
          content="Development company, Software, Application, Mobile App, Website, Wordpress Developer, Outsourcing Projects,talented developers, Frontend Developer, Backend Deleloper, Programs, Codevider, Code, Server, API, Nodejs, Reactjs, AWS, Mysql, Sass, Stripe, Paypal, RestAPI, django, Flask, Python, Docker, Jenkins, Git, Postman, Laravel, Php, JavaScript, ES6, Express, Typescript, koa, hapi, Html5, Angular, Vue, mondoDB, jquery, Symphony, Code in Albania, Develop Website in Albania, Frontend Development in Albania, Web Development Agencies in Albania, Web Development, Web Portfolio,website development outsourcing company, web software outsourcing, web outsourcing company, web development stack, web developers for hire,web app developers, outsource web developer, hire a developer for an app, developer for hire, development outsource, outsource web app development, full stack web developer, angular developers, outsource web application development,   outsource php development , outsource web development services, software design company  , best software developer , software programming company   "
        />
        <Portfolio />
        <SinglePortfolio />
      </Helmet>


      <ScrollToTop />
      <Header />



      <Switch >
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/portfolio">
          <Portfolio />
        </Route>

        <Route exact path="/portfolio/:projectUrl">
          <SinglePortfolio />
        </Route>
      </Switch>

      <Footer />
      {/* </ErrorBoundary> */}
    </Router>
  );
}

export default connect(null, { getPortfolio })(App);
