import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { getPortfolio } from '../store/action'
import { withRouter } from "react-router";
import { loadFunctions } from '../utilities';

const firstFavouriteProject = {
    "id": "first",
    "span": "Portfolio Designs",
    "title": "Creative Work",
    "paragraph": "Because life's too short for crappy software."
};

const lastFavouriteProject = {
    "id": "second",
    "paragraph1": "We’ve Completed More Than ",
    "gradientTitle": "14",
    "paragraph2": "projects for our amazing clients",
    "button": "View All Works",
    "buttonLink": "/portfolio"
};
class PortfolioMasonery extends Component {
    state = {
        favouriteProjects: []
    }
    getFavoriteProjects = () => {
        if (this.props.projects &&
            this.props.projects.projects &&
            this.props.projects.projects.length > 0 &&
            this.state.favouriteProjects.length === 0
        ) {
            const favouriteProjects = this.props.projects.projects.filter(
                (project) => project.displayKey === 'yes'
            );

            this.setState({
                favouriteProjects: [favouriteProjects[0]]
                    .concat(firstFavouriteProject)
                    .concat(favouriteProjects.slice(1, 3))
                    .concat(lastFavouriteProject)
                    .concat(favouriteProjects.slice(4))
            }, () => {
                loadFunctions();
            })
        }
    }
    componentDidMount() {
        // console.log(this.props, prevProps);
        this.getFavoriteProjects();
    }
    componentDidUpdate() {
        this.getFavoriteProjects();

    }
    render() {
        return (

            <section id="portfolio_top" className="bglight page-section" >
                <div className="container">
                    <div id="portfolio-measonry" className=" border-portfolio simple_overlay" >
                        {this.state.favouriteProjects && this.state.favouriteProjects?.map((project, i) => (
                            project && project.id !== 'first' && project.id !== 'second' ? (
                                <div className="cbp-item itemshadow" key={i}>
                                    <img src={project.featuredImage} alt="Web development outsourcing company" title="Codevider software projects" />
                                    <Link to={`/portfolio/${project.url}`} >
                                        <div className="overlay center-block whitecolor animate__fadeInTopLeft">
                                            <h4 className="top20 mb-1 font-weight-bold titleProject ">{project.title}</h4>
                                            <p className="float-left">
                                                <span>Project Categories: <br />
                                                    <span > {project.category?.join(', ')}</span>
                                                </span>
                                            </p>
                                            <p className="float-left">
                                                <span>Started on: </span>
                                                <span className="projectStartDate">{project.startDate}</span>
                                            </p>
                                            <p className="float-left endDate">
                                                {project.endDate ? `Finished on : ${project.endDate}` : `Ongoing Project`}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            ) : (
                                    <div className="cbp-item" key={i}>
                                        {project?.gradientTitle ? (
                                            <div className="bottom-text">
                                                <div className="cells  wow fadeIn" data-wow-delay="350ms">
                                                    <p>{project?.paragraph1}</p>
                                                    <h2 className="port_head gradient_text"> {project?.gradientTitle}</h2>
                                                    <p className="bottom15">{project?.paragraph1}</p>
                                                </div>
                                                <div className="cells wow fadeIn" data-wow-delay="350ms">
                                                    <Link className="button btnsecondary btn-gradient-hvr" to="/portfolio">{project?.button}</Link>
                                                </div>
                                            </div>
                                        ) : (

                                                <div className="text_wrap wow fadeIn" data-wow-delay="350ms">
                                                    <div className="heading-title text-center padding_top">
                                                        <span>{project?.span}</span>
                                                        <h2 className="darkcolor bottom10">{project?.title}</h2>
                                                        <p>{project?.paragraph}</p>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                )
                        ))}
                    </div>
                </div>
            </section>
        )
    };

}
const mapStateToProps = (state) => ({ projects: state.project })

export default connect(mapStateToProps, { getPortfolio })(withRouter(PortfolioMasonery));