import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';


function Whyus() {
  useEffect(() => {
    if (window.$ && window.$("#testimonial-quote-nav") && window.$("#testimonial-quote-nav").owlCarousel) {
      window.$("#testimonial-quote-nav").owlCarousel({
        items: 1,
        autoplay: 2500,
        autoplayHoverPause: true,
        mouseDrag: false,
        loop: true,
        margin: 30,
        animateIn: "fadeIn",
        animateOut: "fadeOut",
        dots: true,
        dotsContainer: "#owl-thumbs",
        nav: true,
        navText: ["<i class='fa fa-arrow-left'></i>", "<i class='fa fa-arrow-right'></i>"],
        responsive: {
          1280: {
            items: 1,
          },
          600: {
            items: 1,
          },
          320: {
            items: 1,
          },
        }
      });
    }
  }, [window.$])
  return (
    <div id="why-us" className="page-section">
      {/* <Helmet>
        <title>Why us | Codevider</title>
        <meta
          name="description"
          content="Outsourcing development company "
        />
      </Helmet> */}
      {/* Mobile Apps */}
      <section className="padding half-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <div className="heading-title wow fadeInUp" data-wow-delay="300ms">
                <h2 className="darkcolor heading_space">Why Us</h2>
              </div>
            </div>
          </div>
          <div className="row" id="app-feature">

            <div className="col-lg-4 col-md-4 col-sm-12 feature-item left top30 bottom30 wow fadeInUp" data-wow-delay="300ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-money" /></span>
              </div>
              <div className="text">
                <h4>Cost Savings</h4>
                <p>You may add up the cost of hiring, training, and housing staff. It is much cheaper to
                outsource an entire project than to hire each developer individually and place the
                    developers on internal teams. </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 feature-item left top30 bottom30 wow fadeInUp" data-wow-delay="350ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-clock-o" /></span>
              </div>
              <div className="text">
                <h4>Time Savings</h4>
                <p>In fast-paced business environments, it is crucial to innovate quickly. And being first
                    in the market can be a critical factor for your success.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 feature-item left top30 bottom30 wow fadeInUp" data-wow-delay="400ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-tasks" /></span>
              </div>
              <div className="text">
                <h4>Online project management</h4>
                <p>You can manage and be in control of your outsourced project by accessing our online
                    platform, monitoring daily tasks and work progress.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 feature-item right top30 bottom30 wow fadeInUp" data-wow-delay="300ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-code" /></span>
              </div>
              <div className="text">
                <h4>Flexibility</h4>
                <p>Software development outsourcing gives you the option to choose developers for a single
                project. You can retain them for future projects or choose another developer if you
                    want.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 feature-item right top30 bottom30 wow fadeInUp" data-wow-delay="350ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-magic" /></span>
              </div>
              <div className="text">
                <h4>Highly creative and motivated teams</h4>
                <p>You’ll have instant access to creative and seasoned professionals by bypassing the gaps
                    in hiring pools you may face in your country.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 feature-item right top30 bottom30 wow fadeInUp" data-wow-delay="400ms">
              <div className="h-100 float-left">
                <span className="icon"><i className="fa fa-free-code-camp" /></span>
              </div>
              <div className="text">
                <h4>Bring in a fresh perspective</h4>
                <p>Writing great software frequently requires trying new approaches and thinking
                    out-of-the-box. You will almost certainly profit from hearing new perspectives. </p>
              </div>
            </div>
            {/*</div>
                </div>*/}
          </div>
        </div>
      </section>
      {/*Mobile Apps ends*/}
      {/* Testimonials */}
      <section className="padding testimonial-bg-light parallaxie half-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div id="owl-thumbs" className="owl-dots text-center bottom45">
                <div className="owl-dot active"><img src="images/Pasho.jpg" alt="" /></div>
                <div className="owl-dot"><img src="images/Ervin.jpg" alt="" /></div>
                <div className="owl-dot"><img src="images/Erion.jpg" alt="" /></div>
                <div className="owl-dot"><img src="images/Altin.jpg" alt="" /></div>
              </div>
              <div id="testimonial-quote-nav" className="owl-carousel no-quote">
                <div className="item">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                      <div className="testimonial-quote whitecolor">
                        <h3 className="bottom15 font-weight-bold">Seamless Communication</h3>
                        <h5 className="bottom30 font-xlight">After outsourcing your development project
                        to CodeVider, we’ll set you up with a dedicated Project Manager with
                            strong English skills, and excellent responsiveness.</h5>
                        <h6>Pasho Toska</h6>
                        <small>Managing Partner</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                      <div className="testimonial-quote whitecolor">
                        <h3 className="bottom15 font-weight-bold">Competitive Prices</h3>
                        <h5 className="bottom30 font-xlight">Outsourcing Projects and development tasks
                        to Eastern Europe saves you cash, without sacrificing code quality.
                        CodeVider is headquartered in Tirana-Albania, which is a well-known hub
                            for finding talented web app developers at budget-friendly prices.</h5>
                        <h6>Ervin Ziko</h6>
                        <small>Finance Manager</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                      <div className="testimonial-quote whitecolor">
                        <h3 className="bottom15 font-weight-bold">Reach a Broader Market</h3>
                        <h5 className="bottom30 font-xlight">Look at this as an opportunity to grow your
                        business in new directions from a global perspective. It allows you to
                        work with talented developers who will take your business to new levels
                            with their performance.</h5>
                        <h6>Erion Domi</h6>
                        <small>Multinational Manager</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                      <div className="testimonial-quote whitecolor">
                        <h3 className="bottom15 font-weight-bold">Intellectual Property Rights</h3>
                        <h5 className="bottom30 font-xlight">Our developers build applications for
                        clients only. This guarantees that clients always own the intellectual
                        property rights to their software 100% of the time. We also sign
                        non-disclosure and non-competition agreements for full legal protection.
                          </h5>
                        <h6>Altin Luli</h6>
                        <small>Outsourcing Manager</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Testimonials Ends*/}
    </div>
  );
}
export default Whyus;