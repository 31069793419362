import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import portfolioCodevider from './reducer'

const initalState = {}

const middleware = [thunk]

const storeCodevider = createStore(portfolioCodevider, initalState, applyMiddleware(...middleware))

export default storeCodevider;