import { GET_PORTFOLIO, PORTFOLIO_ERROR } from './type'
import axios from 'axios'

export const getPortfolio = () => async dispatch => {

    try {
        const res = await axios.get("https://ptoska.github.io/codevider-portofolio/projects.json")
        dispatch({
            type: GET_PORTFOLIO,
            payload: res.data.project
        });
        // console.log(res);
        // clearTimeout(() => {
        // window.$("#flat-gallery").cubeportfolio('destroy');
        // })
    }
    catch (e) {
        dispatch({
            type: PORTFOLIO_ERROR,
            payload: console.log(e),
        })
    }

} 