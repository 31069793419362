import React from 'react';
import { Helmet } from 'react-helmet';


function Services() {
    return (
        <section id="our-services" className="padding bglight page-section">
            {/* <Helmet>
                <title>Services | Codevider</title>
                <meta
                    name="description"
                    content="Outsourcing development company "
                />
            </Helmet> */}
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-sm-12 text-center">
                        <div className="heading-title wow fadeInUp" data-wow-delay="300ms">
                            <h2 className="darkcolor bottom30">Our Services</h2>
                            <p> Below, you can check the details of our offer:</p>
                        </div>
                    </div>
                </div>
                <div className="row three-col-pricing">
                    <div className="col-lg-4 col-sm-12">
                        <div className="price-table top60 height-fixed">
                            <div className="service-icons bottom20 defaultcolor wow fadeInUp" data-wow-delay="300ms"><i className="fa fa-html5" /></div>
                            <h3 className="bottom40 darkcolor">FrontEnd</h3>
                            <div className="card-body">
                                <p className="card-text">HTML5, CSS3, Sass, Gulp, Bootstrap </p>
                                <p className="card-text">JavaScript (ECMAScript 6,7,8), React, Vue.js, Angular, jQuery,
                            TypeScript</p>
                                <p className="card-text">Google Maps API, Facebook API, PayPal, Stripe</p>
                                <p className="card-text">Socket.io, GraphQL, Axios, Rest Api</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="price-table top60 height-fixed" data-wow-delay="400ms">
                            <div className="service-icons bottom20 defaultcolor wow fadeInUp" data-wow-delay="300ms"><i className="fa fa-code" /></div>
                            <h3 className="bottom20 darkcolor">BackEnd</h3>
                            <div className="card-body">
                                <p className="card-text">Node Js, Python, Php </p>
                                <p className="card-text">Node Frameworks: ExpressJs, HapiJs, Koa Js</p>
                                <p className="card-text">Python Frameworks: Django, Flask</p>
                                <p className="card-text">Php Frameworks: Laravel, Symphony, Zend</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="price-table top60 height-fixed">
                            <div className="service-icons bottom20 defaultcolor wow fadeInUp" data-wow-delay="300ms"><i className="fa fa-database" /></div>
                            <h3 className="bottom20 darkcolor">Database</h3>
                            <div className="card-body">
                                <p className="card-text">Relational: MySQL, PostgresSQL</p>
                                <p className="card-text">NoSQL: MongoDB, DynamoDB, Redis</p>
                                <p className="card-text">&nbsp;</p>
                                <p className="card-text">&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;