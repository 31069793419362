import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getPortfolio } from '../store/action'
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { loadFunctions } from '../utilities';

const location = process.env.REACT_APP_BASE_NAME || '/';

class Portfolio extends Component {

  componentDidMount() {
    if (this.props.project.projects.length)
      loadFunctions()
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.project.projects.length && this.props.project.projects.length))
      loadFunctions()
  }

  render() {
    const { projects } = this.props.project;

    return (
      <div>
        <Helmet>
          <title>Codevider Portfolio</title>
          <meta
            name="description"
            content="Web development projects, applications, Frontend and Backend Development "
          />
          <meta
            name="keywords"
            content={`}Development company, Software, Application, Mobile App, Website, Wordpress Developer, Outsourcing Projects,talented developers, Frontend Developer, Backend Deleloper, Programs, Codevider, Code, Server, API, Nodejs, Reactjs, AWS, Mysql, Sass, Stripe, Paypal, RestAPI, django, Flask, Python, Docker, Jenkins, Git, Postman, Laravel, Php, JavaScript, ES6, Express, Typescript, koa, hapi, Html5, Angular, Vue, mondoDB, jquery, Symphony, Code in Albania, Develop Website in Albania, Frontend Development in Albania, Web Development Agencies in Albania, Web Development, Web Portfolio,website development outsourcing company, web software outsourcing, web outsourcing company, web development stack, web developers for hire,web app developers, outsource web developer, hire a developer for an app, developer for hire, development outsource, outsource web app development, full stack web developer, angular developers, outsource web application development,   outsource php development , outsource web development services, software design company  , best software developer , software programming company `}
          />

        </Helmet>
        {/*page Header*/}
        <section className="page-header parallaxie bglight center-block padding_top_m py-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-titles text-left ">
                  <h2 className=" font-light font-weight-bold mt-5">PORTFOLIO</h2>
                  <ul className="breadcrumb justify-content-left">
                    <li className="breadcrumb-item text-primary">
                      <Link to="/"
                      // onClick={() => { window.location.href = "/"; }}
                      >Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Creative Work</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*page Header ends*/}

        {/*Gallery*/}
        <section id="portfolio-xe" className="padding_m  py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-2 col-sm-12 ">
                <div id="flat-filters" className="cbp-l-filters dark bottom30">
                  <div data-filter="*" className="cbp-filter-item">
                    <span>All</span>
                  </div>
                  <div data-filter=".Application" className="cbp-filter-item">
                    <span>App</span>
                  </div>
                  <div data-filter=".Web" className="cbp-filter-item">
                    <span>Web</span>
                  </div>
                  <div data-filter=".Mobile" className="cbp-filter-item">
                    <span>Mobile</span>
                  </div>
                  <div data-filter=".WordPress" className="cbp-filter-item">
                    <span>Wordpress</span>
                  </div>
                  <div data-filter=".active" className="cbp-filter-item">
                    <span>Ongoing</span>
                  </div>
                  <div data-filter=".completed" className="cbp-filter-item">
                    <span>Completed</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="flat-gallery">
              {projects && projects?.map((project, i) => (

                <div key={i} className={`cbp-item portfolioItem ${project.category.join(' ')} ${project.status === `active` ? `active` : `completed`}`} >

                  <img src={project.featuredImage} alt={project.title} className="featuredImg" />
                  <Link to={`/portfolio/${project.url}`} >

                    <div className="overlay center-block whitecolor animate__fadeInTopLeft">
                      <h4 className="top20 mb-1 font-weight-bold titleProject" > {project.title} </h4>

                      <p key={i} className="float-left w-75 text-center">
                        <span>Project Categories: <br />
                          <span > {project.category.join(', ')}</span>
                        </span>
                        <span className="catProjectList"></span>
                      </p>
                      <p className="float-left">
                        <span>Started on: </span>
                        <span className="projectStartDate">{project.startDate}</span>
                      </p>
                      <p className="float-left endDate">
                        {project.endDate ? `Finished on : ${project.endDate}` : `Ongoing Project`}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/*Gallery ends */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ project: state.project })

export default connect(mapStateToProps, { getPortfolio })(Portfolio);